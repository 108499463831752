import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./utils/cookie-manager";
import { decodeToken } from "./utils/decoded-token";
import PropTypes from "prop-types";
import Anasayfa from "./pages/Anasayfa";
import KapiMotor from "./pages/Dashboard/KapiMotoru";
import Dashboard from "./pages/Dashboard/Main";
import Giris from "./pages/Login";

const userRole = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const token = getCookie("access_token");
      if (!token) {
        console.warn("access_token bulunamadı");
        resolve(null);
        return;
      }
      const decodedToken = decodeToken(token);
      resolve(decodedToken ? decodedToken.role : null);
    }, 100); // 100ms gecikme
  });
};

function PrivateRoute({ children }) {
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    userRole().then(role => {
      setIsAuthorized(!!role);
    });
  }, []);

  if (isAuthorized === null) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? children : <Navigate to="/giris" replace state={{ from: location }} />;
}

function PublicRoute({ children }) {
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    userRole().then(role => {
      setIsAuthorized(!!role);
    });
  }, []);

  if (isAuthorized === null) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? <Navigate to="/dashboard" replace state={{ from: location }} /> : children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

function App() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path="/"
        element={
          <PublicRoute>
            <Anasayfa />
          </PublicRoute>
        }
      />
      <Route
        path="/giris"
        element={
          <PublicRoute>
            <Giris />
          </PublicRoute>
        }
      />

      {/* Private Routes */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/kapimotoru"
        element={
          <PrivateRoute>
            <KapiMotor />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;