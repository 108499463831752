import React from "react";
import { menuItem } from "../utils/menuItem";
import { NavLink } from "react-router-dom";
import {
  FaUserCircle,
  FaHome,
  FaPlusCircle,
  FaListUl,
  FaQuestionCircle,
  FaRss,
  FaFolder,
} from "react-icons/fa";

function DashboardLayout({ children }) {
  const getIcon = (name) => {
    switch (name) {
      case "Profile":
        return <FaUserCircle className="mr-2" />;
      case "Main":
        return <FaHome className="mr-2" />;
      case "Add Music":
        return <FaPlusCircle className="mr-2" />;
      case "All Music":
        return <FaListUl className="mr-2" />;
      case "My Music":
        return <FaQuestionCircle className="mr-2" />;
      case "Feed":
        return <FaRss className="mr-2" />;
      case "Catagories":
        return <FaFolder className="mr-2" />;
      default:
        return null;
    }
  };
  return (
    <div className="flex h-screen bg-gray-900">
      <div className="w-60 text-white shadow-xl">
        <div className="p-6">
          <h1 className="text-3xl font-extrabold border-b  border-gray-700 text-indigo-500 pb-5">
            Dashboard
          </h1>
          <ul className="space-y-6">
            {menuItem.map((section, index) => (
              <li key={index}>
                <div className="text-lg font-semibold underline text-gray-500 mb-4">
                  {section.name}
                </div>
                <ul className="space-y-4">
                  {section.items.map((item, index) => (
                    <li className="flex items-center mb-2" key={index}>
                      {getIcon(item.name)}
                      <NavLink to={`/${item.slug}`}>{item.name}</NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex-1 bg-gray-800 rounded-lg shadow-lg">{children}</div>
    </div>
  );
}

export default DashboardLayout;
