// services/GetRequest.js
import { getCookie } from "../utils/cookie-manager";
import { toast } from "react-toastify";

// API URL'sini .env dosyasından alıyoruz
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const referacneExel = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/read/ref-exel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer: ${getCookie("access_token")}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const productRead = async (firm) => {
  try {
    const response = await fetch(`${API_BASE_URL}/read/productRead/${firm}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer: ${getCookie("access_token")}`,
      },
    });
    const data = await response.json();
    if (data) {
      toast.success(`${firm} verileri başarıyla getirildi`);
    }
    return data;
  } catch (err) {
    toast.error(`Veri Çekme Hatası : ${err}`);
    console.log(err);
    return [];
  }
};

export const updatePrices = async (site) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/read/scrape-prices?site=${site}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer: ${getCookie("access_token")}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
