import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layout/DashboardLayout";
import {
  referacneExel,
  productRead,
  updatePrices,
} from "../../../services/GetRequest";
import { comparePrices } from "../../../utils/comparePrices";
import { FaSpinner } from "react-icons/fa";

const normalizeProductName = (name) => {
  return name.toLowerCase().replace(/\s+/g, "");
};

const compareProductNames = (name1, name2) => {
  const normalized1 = normalizeProductName(name1);
  const normalized2 = normalizeProductName(name2);
  return normalized1 === normalized2;
};

const formatPrice = (price) => {
  if (price === "N/A" || price === null || price === undefined) return "N/A";
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
  }).format(price);
};

function KapiMotor() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          updatePrices("uzmankapi"),
          updatePrices("kapimotoru"),
          updatePrices("motodoor"),
        ]);

        const referacneExelData = await referacneExel();

        const [uzmankapiData, kapimotoruData, motodoorData] = await Promise.all(
          [
            productRead("uzmankapi"),
            productRead("kapimotoru"),
            productRead("motodoor"),
          ]
        );

        if (!Array.isArray(referacneExelData)) {
          throw new Error("Referans Excel verileri dizi formatında değil");
        }

        const updatedFirmPrices = {
          uzmankapi: uzmankapiData.success ? uzmankapiData.data : [],
          kapimotoru: kapimotoruData.success ? kapimotoruData.data : [],
          motodoor: motodoorData.success ? motodoorData.data : [],
        };

        const getPriceByFirm = (data, productName) => {
          const product = data.find((item) =>
            compareProductNames(item.newName, productName)
          );
          return product ? product.price : "N/A";
        };

        const mergedData = referacneExelData.map((excelProduct) => {
          const uzmankapiPrice = getPriceByFirm(
            updatedFirmPrices.uzmankapi,
            excelProduct.product_name
          );

          return {
            productName: excelProduct.product_name,
            refPrice: excelProduct.product_price,
            uzmankapiPrice,
            kapimotoruPrice: getPriceByFirm(
              updatedFirmPrices.kapimotoru,
              excelProduct.product_name
            ),
            motodoorPrice: getPriceByFirm(
              updatedFirmPrices.motodoor,
              excelProduct.product_name
            ),
          };
        });

        setProducts(mergedData);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const normalizeProductName = (name) => {
    return name
      .toLowerCase() // Küçük harfe çevir
      .replace(/[\u0130]/g, "i") // Büyük 'İ' harfini küçük 'i' ile değiştir
      .replace(/[\u0131]/g, "i") // Küçük 'ı' harfini küçük 'i' ile değiştir
      .replace(/[ş]/g, "s") // 'ş' harfini 's' ile değiştir
      .replace(/[ç]/g, "c") // 'ç' harfini 'c' ile değiştir
      .replace(/[ğ]/g, "g") // 'ğ' harfini 'g' ile değiştir
      .replace(/[ü]/g, "u") // 'ü' harfini 'u' ile değiştir
      .replace(/[ö]/g, "o") // 'ö' harfini 'o' ile değiştir
      .replace(/[İ]/g, "i") // Büyük 'İ' harfini küçük 'i' ile değiştir
      .replace(/[\W_]+/g, "") // Tüm özel karakterleri ve boşlukları kaldır
      .trim(); // Başta ve sonda boşlukları kaldır
  };

  const filteredProducts = products.filter((product) => {
    const normalizedProductName = normalizeProductName(product.productName);
    const normalizedSearchTerm = normalizeProductName(searchTerm);

    return normalizedProductName.includes(normalizedSearchTerm);
  });

  return (
    <DashboardLayout>
      <div className="flex flex-col p-4 h-full">
        <h1 className="text-2xl font-bold mb-4 text-white">
          Ürün Fiyat Karşılaştırma
        </h1>

        {/* Arama Kutusu */}
        <div className="flex justify-center mb-4">
          <input
            type="text"
            placeholder="Ürün ara..."
            className="p-2 border border-gray-300 rounded w-1/2"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
        <div className="flex-grow overflow-y-auto rounded-xl">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <FaSpinner className="animate-spin text-6xl text-white" />
            </div>
          ) : (
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="sticky top-0 bg-gray-100 border-b border-gray-200">
                <tr>
                  <th className="text-center py-2 px-4 w-1/12">No</th>
                  <th className="text-center py-2 px-4 w-1/5">
                    İzmit Otomatik Kapı
                  </th>
                  <th className="text-center py-2 px-4 w-1/5">
                    İzmit Otomatik Kapı Fiyatı
                  </th>
                  <th className="text-center py-2 px-4 w-1/5">Uzman Kapi</th>
                  <th className="text-center py-2 px-4 w-1/5">Kapi Motoru</th>
                  <th className="text-center py-2 px-4 w-1/5">Motodoor</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b border-gray-200 break-words font-semibold text-gray-800 text-center">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 break-words font-semibold text-gray-800 text-center">
                      {product.productName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 break-words text-sm text-gray-600 text-center">
                      {formatPrice(product.refPrice)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 break-words text-sm text-gray-600 text-center">
                      {formatPrice(product.uzmankapiPrice)}{" "}
                      {comparePrices(product.refPrice, product.uzmankapiPrice)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 break-words text-sm text-gray-600 text-center">
                      {formatPrice(product.kapimotoruPrice)}{" "}
                      {comparePrices(product.refPrice, product.kapimotoruPrice)}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 break-words text-sm text-gray-600 text-center">
                      {formatPrice(product.motodoorPrice)}{" "}
                      {comparePrices(product.refPrice, product.motodoorPrice)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default KapiMotor;
