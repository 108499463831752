export const menuItem = [
  {
    name: "",
    items: [
      {
        name: "Anasayfa",
        slug: "dashboard",
        description: "Anasayfa ve Menü Alanı"
      },
    ],
  },
  {
    name: "FORM",
    items: [
      {
        name: "Kapı Motorları",
        slug: "dashboard/kapimotoru",
        description: "Dairesel ve Yana Kayar Kapı Motorları"
      },
    ],
  },
];
