import { FaArrowUp, FaArrowDown, FaQuestion } from "react-icons/fa";
export const comparePrices = (refPrice, firmPrice) => {
  if (firmPrice === "N/A") {
    return <FaQuestion className="text-gray-500 inline ml-1" />;
  } else if (parseFloat(firmPrice) < parseFloat(refPrice)) {
    return <FaArrowDown className="text-red-500 inline ml-1" />;
  } else if (parseFloat(firmPrice) > parseFloat(refPrice)) {
    return <FaArrowUp className="text-green-500 inline ml-1" />;
  } else {
    return "";
  }
};
