import React from "react";
import { useNavigate } from "react-router-dom";

function Anasayfa() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/giris");
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-blue-400 via-indigo-600 to-purple-700 animate-gradient-x transition-all duration-3000 ease-in-out">
      <h1 className="text-6xl font-bold text-center mb-8 text-white">
        Faberkgroup Fiyat Karşılaştırma
      </h1>
      <p className="text-white text-2xl mb-6 text-center">
        Ürün fiyatlarını en hızlı ve kolay şekilde karşılaştırın.
      </p>
      <div className="flex justify-center mb-8">
        <button
          onClick={handleLoginClick}
          className="transform bg-green-500 py-2 px-6 rounded-full shadow-lg text-white font-semibold hover:bg-green-400 transition-transform duration-500 hover:scale-105"
        >
          Giriş Yap
        </button>
      </div>
    </div>
  );
}

export default Anasayfa;
